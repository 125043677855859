<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <van-cell-group>
      <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
      <van-cell v-for="item in answerList" :key="item.id.toString()">
        <div style="color: #9d1d22">问题：{{ item.questionTitle }}</div>
        <div v-if="item.answerID != undefined && item.answerID.length > 0">
          答案:{{ item.answer }}
        </div>
        <div v-if="item.answerID == undefined || item.answerID.length == 0">
          <div v-if="item.answerText.indexOf('http://znpzkm.oss-cn-beijing.aliyuncs.com/wwwroot') >= 0">
            <van-image v-for="image in  item.answerText.split(',')" :key="image" width="100%" fit="contain"
              :src='image'>
            </van-image>
          </div>
          <div v-else>答案:{{ item.answerText }}</div>
        </div>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { GetAnserDetail } from "@/api/questionnaire";
export default {
  name: "anserDetail",

  data() {
    return {
      scroll: 0,
      refreshing: false,
      upLoading: false,
      finished: false,
      answerId: 0,
      title: "客户填写明细",
      answerList: [],
    };
  },
  watch: {
    // $route(to, from) {
    //   //监听路由是否变化
    //   if (to.path == "/question/answerdetail") {
    //     if (to.query != from.query) {
    //       this.loading = true;
    //       this.initData();
    //     }
    //   }
    // },
  },

  activated() { },
  created() {
    this.initData();
  },

  mounted() { },

  methods: {
    initData() {
      this.answerId = this.$route.query.id;
      this.onLoad();
    },

    async onLoad() {
      if (this.$route.path == "/question/answerdetail") {
        this.answerList = [];
        let answerListresult = await GetAnserDetail(this.answerId); //({ ...this.page })
        this.answerList.push(...answerListresult.data.data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  padding: 5px;
}

td {
  padding: 5px;
}
</style>
