import request from '@/utils/request'

export function getQuestionnaireList(params) {
    return request({
        url: '/api/Questionnaire/ListByUser',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

/**
 * 星光计划列表
 * @param {*} params 
 * @returns 
 */
export function getQuestionnaireList2(params) {
    return request({
        url: '/api/Questionnaire/ListByUser2',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

export function getQuestionnaireListByTypeId(params) {
    return request({
        url: '/api/Questionnaire/ListByTypeID',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}
///api/Questionnaire


// 
export function getQuestionnaire (id) {
    return request({
      url: `/api/Questionnaire?para=${id}`
    })
  }




export function getPosterList(params) {
    return request({
        url: '/api/Questionnaire/ListPosterByUser',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}
export function getPosterListPara(params) {
    return request({
        url: '/api/Questionnaire/ListPosterByUserPara',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}

export function getAnswerList(params) {
    return request({
        url: '/api/QuestionnaireAnswer/ListByUser',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}
export function getAnswerList2(params) {
    return request({
        url: '/api/QuestionnaireAnswer/ListByUser2',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}


export function  getDeviceCountByUser(qid) {
    return request({
      url: `/api/QuestionnaireAnswer/ListCountByUser?qid=${qid}`
    })
}


export function  getAnswerCountByUser(qid) {
    return request({
      url: `/api/QuestionnaireAnswer/ListAnswerCountByUser?qid=${qid}`
    })
}

export function  GetAnserDetail(id) {
    return request({
      url: `/api/QuestionnaireAnswer/GetAnserDetail?id=${id}`
    })
}